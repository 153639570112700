/*:global(.async-hide) {*/
/*  opacity: 0 !important;*/
/*}*/
body {
  font-family: "Source Sans Pro", sans-serif;
}
.container {
  display: -ms-flexbox;
  display: flex;
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
}
.src-containers-App---updateTopBar--1DBw7 {
  position: fixed;
  width: 100%;
  z-index: 10;
  padding: 9px 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  background-color: var(--color-background);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  top: -67px;
  transition: 1s all ease-in-out
}
.src-containers-App---updateTopBar--1DBw7.src-containers-App---active--9x4gL {
    top: 0;
  }
.src-containers-App---versionApp--b9idy {
  position: absolute;
  z-index: -4;
  bottom: 0;
  right: 5px;
  font-size: 11px;
  color: #adadad;
}
@media screen and (max-width: 1024px) {
  .container {
    max-width: 500px;
  }
}
@media screen and (max-width: 540px) {
  .container {
    max-width: 90%;
    min-width: 300px;
  }
}
