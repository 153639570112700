.src-containers-VerifyPage---verification-card--sSBSO {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
  padding: 20px;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(195, 195, 195, 0.24);
  border-radius: 11px;
  background-color: #fff;
  box-shadow: -1px 3px 5px 0 rgb(156 156 156 / 10%);
  opacity: 1;
  text-decoration: none;
  max-width: 100%;
  min-height: 180px;
  width: 100%;
}
