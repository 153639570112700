.src-components-Header---wrapper--kdzQb {
  margin-right: 0;
  margin-left: 0;
  border-bottom: 1px solid hsla(0, 0%, 74%, 0.3);
  background-color: #fff
}
.src-components-Header---wrapper--kdzQb .src-components-Header---logo--3SEpy {
    letter-spacing: -4px;
    display: block;
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
    opacity: 0.5;
  }
.src-components-Header---wrapper--kdzQb h1 {
    font: var(--font-heading);
    padding: 20px 0;
    font-weight: 400;
    font-size: larger
  }
.src-components-Header---wrapper--kdzQb h1 span {
      color: #96a1af;
    }
.src-components-Header---wrapper--kdzQb .src-components-Header---header_logo--2tNWJ:hover {
    cursor: pointer;
  }
