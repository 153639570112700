.src-components-ImgModal---modal--2N3DJ {
  display: inline-block;
  position: relative;
  outline: none !important;
  max-width: 845px;
  width: 100%;
  border: none;
  background: #fbfbfb;
  box-shadow: var(--elevation-flat);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: center;
      justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  padding: var(--gutter);
  overflow: scroll;
  z-index: var(--layer-top)
}

@media screen and (max-width: 540px) {

.modal-overlay {
    padding: 0
}
  }

.src-components-ImgModal---modal-close--2AMgv {
  position: absolute;
  top: 1em;
  right: 1em;
  height: var(--scale-00);
  width: var(--scale-00);
  fill: var(--color-text-light);
  cursor: pointer;
  transition: fill 200ms ease
}

.src-components-ImgModal---modal-close--2AMgv:hover {
    fill: var(--color-text);
  }

/**
 * Transitions
 */

.src-components-ImgModal---modal--2N3DJ,
.modal-overlay {
  transition: opacity 300ms var(--easing-standard);
}

.src-components-ImgModal---modal--2N3DJ,
.modal-overlay.open,
.modal-overlay.open.closing .src-components-ImgModal---modal--2N3DJ,
.modal-overlay.open .src-components-ImgModal---modal--2N3DJ {
  opacity: 1;
}

.modal-overlay,
.modal-overlay.open.closing {
  opacity: 0;
}
