.src-components-ContentsWarp---contents_wrap--1yk0A {
  margin-top: 60px;
  margin-bottom: 30px;
  width: 100%;
}

@media screen and (max-width: 540px) {
  .src-components-ContentsWarp---contents_wrap--1yk0A {
    margin-top: 40px;
  }
}
