.src-components-Result---result--3uNON {
  width: 100%;
  margin: 20px 0 0
}
.src-components-Result---result--3uNON .spacer {
    height: 20px;
  }
.src-components-Result---result--3uNON .src-components-Result---div-block--3u-0g {
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding: 10px;
    -ms-flex-pack: justify;
    justify-content: flex-start;
    -ms-flex-align: center;
        align-items: center
  }
.src-components-Result---result--3uNON .src-components-Result---div-block--3u-0g.src-components-Result---info--1E-Fk {
      -ms-flex-pack: justify;
          justify-content: space-between;
      border-radius: 11px;
      padding: 15px;
      background-color: #fbfbfb;
    }
.src-components-Result---result--3uNON .src-components-Result---div-block--3u-0g.src-components-Result---info-2-line--1FaCH {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-align: initial;
          align-items: initial;
    }
.src-components-Result---result--3uNON .src-components-Result---div-block--3u-0g i {
      color: var(--color-green);
      font-size: 25px;
      margin-right: 20px;
    }
.src-components-Result---result--3uNON .src-components-Result---div-block--3u-0g h2 {
      margin: 0;
      font-family: "Source Sans Pro", sans-serif;
      color: var(--color-header-text);
      font-size: 20px;
      font-weight: 400;
    }
.src-components-Result---result--3uNON .src-components-Result---div-block--3u-0g .src-components-Result---bold--Ckk8S {
      font-weight: 600;
    }
.src-components-Result---result--3uNON .src-components-Result---div-block--3u-0g .src-components-Result---hash-text--VFYA7 {
      color: var(--color-hash-text);
      word-break: break-all;
      font-size: 18px;
    }
.src-components-Result---result--3uNON .src-components-Result---div-block--3u-0g .src-components-Result---bold--Ckk8S {
      font-weight: 600;
      margin-bottom: 5px;
    }
.src-components-Result---result--3uNON .src-components-Result---img-block--1jXOt {
    width: 100%;
    padding: 10px;
    border-radius: 11px;
    padding: 15px;
    background-color: #fbfbfb
  }
.src-components-Result---result--3uNON .src-components-Result---img-block--1jXOt h2 {
      margin: 0;
      font-family: "Source Sans Pro", sans-serif;
      color: #919dab;
      font-size: 20px;
      font-weight: 400;
      padding-bottom: 15px;
    }
.src-components-Result---result--3uNON .src-components-Result---img-block--1jXOt img {
      width: 100%;
    }
.src-components-Result---result--3uNON .src-components-Result---modal_img--HSk9z {
    width: 800px;
  }
.src-components-Result---search--y859m {
  transition: all ease-in-out 1s;
  overflow: hidden;
  height: 150px
}
.src-components-Result---search--y859m ul li {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      position: relative;
    }
.src-components-Result---search--y859m.loading-done {
    opacity: 0;
    height: 0;
  }
.src-components-Result---modal_img--HSk9z {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 1024px) {
  .src-components-Result---result--3uNON {
    margin: 15px 0 0
  }
    .src-components-Result---result--3uNON .spacer {
      height: 15px;
    }
      .src-components-Result---result--3uNON .src-components-Result---div-block--3u-0g.src-components-Result---info--1E-Fk {
        padding: 15px;
      }

      .src-components-Result---result--3uNON .src-components-Result---div-block--3u-0g i {
        color: var(--color-green);
        font-size: 20px;
        margin-right: 10px;
      }
      .src-components-Result---result--3uNON .src-components-Result---div-block--3u-0g h2 {
        font-size: 16px;
      }
      .src-components-Result---result--3uNON .src-components-Result---div-block--3u-0g .src-components-Result---hash-text--VFYA7 {
        font-size: 15px;
      }
      .src-components-Result---result--3uNON .src-components-Result---img-block--1jXOt h2 {
        font-size: 15px;
        padding-bottom: 10px;
      }
      .src-components-Result---result--3uNON .src-components-Result---img-block--1jXOt img {
        width: 100%;
      }
        .src-components-Result---search--y859m ul li i {
          font-size: 15px;
        }
}
@media screen and (max-width: 540px) {
  .src-components-Result---result--3uNON {
    margin: 10px 0 0
  }
    .src-components-Result---result--3uNON .spacer {
      height: 10px;
    }
      .src-components-Result---result--3uNON .src-components-Result---div-block--3u-0g.src-components-Result---info--1E-Fk {
        padding: 10px;
      }

      .src-components-Result---result--3uNON .src-components-Result---div-block--3u-0g i {
        color: var(--color-green);
        font-size: 15px;
        margin-right: 10px;
      }
      .src-components-Result---result--3uNON .src-components-Result---div-block--3u-0g h2 {
        font-size: 15px;
      }
      .src-components-Result---result--3uNON .src-components-Result---div-block--3u-0g .src-components-Result---hash-text--VFYA7 {
        font-size: 14px;
      }
      .src-components-Result---result--3uNON .src-components-Result---img-block--1jXOt h2 {
        font-size: 15px;
        padding-bottom: 10px;
      }
        .src-components-Result---search--y859m ul li i {
          font-size: 15px;
        }
}
