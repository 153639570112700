.src-components-Hero---wrapper--v8KNk {
  background-color: var(--color-background)
}
.src-components-Hero---wrapper--v8KNk .container .src-components-Hero---row--1LNUs {
      -ms-flex-align: center;
          align-items: center;
    }
.src-components-Hero---wrapper--v8KNk .container .src-components-Hero---column--1vOTp {
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-align: start;
          align-items: flex-start;
      padding-top: 60px
    }
@media screen and (max-width: 540px) {
  .src-components-Hero---wrapper--v8KNk .container .src-components-Hero---column--1vOTp {
        padding-top: 38px
    }
      }
.src-components-Hero---wrapper--v8KNk .container > div {
      display: -ms-flexbox;
      display: flex;
      padding: 40px 0;
      width: 100%
    }
@media screen and (max-width: 540px) {
  .src-components-Hero---wrapper--v8KNk .container > div {
        display: block;
        padding: 30px 10px
    }
      }
.src-components-Hero---wrapper--v8KNk .container > div svg {
        height: 60px;
        padding-right: 20px;
        fill: red
      }
@media screen and (max-width: 540px) {
  .src-components-Hero---wrapper--v8KNk .container > div svg {
          height: 40px;
          margin-bottom: 7px
      }
        }
.src-components-Hero---wrapper--v8KNk .container > div .src-components-Hero---green--1O5x0 path {
        fill: #48c9b0;
      }
.src-components-Hero---wrapper--v8KNk .container > div .src-components-Hero---red--1AdeF path {
        fill: red;
      }
.src-components-Hero---wrapper--v8KNk .container > div .src-components-Hero---content--d3x8p h1 {
          color: var(--color-header-text);
        }
.src-components-Hero---wrapper--v8KNk .container > div .src-components-Hero---content--d3x8p p {
          margin: 0
        }
@media screen and (max-width: 540px) {
  .src-components-Hero---wrapper--v8KNk .container > div .src-components-Hero---content--d3x8p p {
            margin-top: 7px
        }
          }
