
  .src-components-Rending---reading_wrapper--3KeqQ .src-components-Rending---header_text--2ueED {
    line-height: 44px;
    font-size: 38px;
    margin-top: 20px;
  }

.src-components-Rending---reading_wrapper--3KeqQ br {
    display: none;
  }

.src-components-Rending---reading_wrapper--3KeqQ .src-components-Rending---text_box--QNOT9 {
    max-width: 754px;
    border-bottom: solid 2px rgba(150, 161, 175, 0.5);
    margin: 0
  }

.src-components-Rending---reading_wrapper--3KeqQ .src-components-Rending---text_box--QNOT9 p {
      color: #2c2e30;
      line-height: 32px;
      margin: 0;
      font-size: 20px;
    }

.src-components-Rending---reading_wrapper--3KeqQ .src-components-Rending---text_box--QNOT9 strong {
      padding: 20px;
      display: block;
      margin: 0;
    }

@media screen and (max-width: 1360px) {
    .src-components-Rending---reading_wrapper--3KeqQ .src-components-Rending---header_text--2ueED {
      line-height: 38px;
      font-size: 33px;
      margin-top: 15px;
    }
    .src-components-Rending---reading_wrapper--3KeqQ .src-components-Rending---text_box--QNOT9 {
      border-bottom: solid 2px rgba(150, 161, 175, 0.5);
      padding-bottom: 20px
    }
      .src-components-Rending---reading_wrapper--3KeqQ .src-components-Rending---text_box--QNOT9 p {
        line-height: 28px;
        margin-bottom: 10px;
        font-size: 18px;
      }
}

@media screen and (max-width: 540px) {
    .src-components-Rending---reading_wrapper--3KeqQ .src-components-Rending---header_text--2ueED {
      line-height: 33px;
      font-size: 30px;
      margin-top: 5px;
    }
    .src-components-Rending---reading_wrapper--3KeqQ br {
      display: block;
    }

    .src-components-Rending---reading_wrapper--3KeqQ .src-components-Rending---text_box--QNOT9 {
      border-bottom: solid 2px rgba(150, 161, 175, 0.5);
      padding-bottom: 10px
    }
      .src-components-Rending---reading_wrapper--3KeqQ .src-components-Rending---text_box--QNOT9 p {
        line-height: 20px;
        font-size: 16px;
      }
      .src-components-Rending---reading_wrapper--3KeqQ .src-components-Rending---text_box--QNOT9 strong {
        padding: 10px;
      }
}
