.src-components-Upload---upload--3rBvR {
  height: 100%;
  border-style: dashed;
  border-width: 1px;
  border-color: hsla(0, 0%, 74.1%, 0.3);
  border-radius: 11px;
  background-color: var(--color-background);
  width: 100%
}
.src-components-Upload---upload--3rBvR > div {
    height: 100%;
    transition: all ease-in-out 0.5s;
  }
.src-components-Upload---upload--3rBvR:hover {
    background-color: #ffffff;
  }
.src-components-Upload---upload--3rBvR .src-components-Upload---drag--1F2Vu {
    background-color: #fbfbfb;
  }
.src-components-Upload---upload--3rBvR label {
    cursor: pointer;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 180px;
    transition: all 0.5s ease-in-out
  }
.src-components-Upload---upload--3rBvR label.small-upload {
      min-height: 50px;
    }
.src-components-Upload---upload--3rBvR label span {
      margin: auto;
      font-family: "Source Sans Pro", sans-serif;
      color: #919dab;
      font-size: 20px;
      font-weight: 400;
      line-height: 36px;
    }
@media screen and (max-width: 1024px) {
    .src-components-Upload---upload--3rBvR label {
      min-height: 130px
    }
      .src-components-Upload---upload--3rBvR label span {
        font-size: 17px;
        font-weight: 400;
        line-height: 20px;
      }
}
@media screen and (max-width: 540px) {
    .src-components-Upload---upload--3rBvR label {
      min-height: 100px
    }
      .src-components-Upload---upload--3rBvR label span {
        font-size: 14px;
        font-weight: 400;
        line-height: 15px;
      }
}
